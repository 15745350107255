import IconCheck from "assets/icon-check.svg";
import IconEdit from "assets/icon-edit.svg";
import Button from "components/material/button/button";
import { DataLayerEvents, DataLayerNames } from "constants/dataLayerEvent";
import { StorageItems } from "constants/storageItems";
import LoaderTypes from "enums/loaderTypes";
import TranslationMapper from "i18n/mapper";
import LanguageProvider from "providers/languageProvider";
import SessionStorageProvider from "providers/sessionProvider";
import { ChangeEvent, Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  getAddressAlreadyRegisteredAsync,
  getReadAddress,
  getReadZipCodeCheck,
  setUpdateZipCodeValid,
} from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import { ScriptUtil } from "utils/scriptUtil";

import { IZipCodeDispatchProps, IZipCodeProps, IZipCodeStateProps } from "./interfaces/IZipcodeProps";
import { IZipCodeState } from "./interfaces/IZipcodeState";

class ZipCode extends Component<IZipCodeProps, IZipCodeState> {
  public constructor(props: IZipCodeProps) {
    super(props);

    this.state = this.defaultState();

    this.handleEdit = this.handleEdit.bind(this);
    this.handleZipCodeCheckResult = this.handleZipCodeCheckResult.bind(this);
    this.onHouseNumberAdditionChange = this.onHouseNumberAdditionChange.bind(this);
    this.onHouseNumberChange = this.onHouseNumberChange.bind(this);
    this.onZipCodeChange = this.onZipCodeChange.bind(this);
  }

  private defaultState(): IZipCodeState {
    const state: IZipCodeState = {
      isFormValidated: false,
      addressExists: false,
      zipCode: this.props.zipCode,
      address: this.props.address,
    };

    return state;
  }

  private get isDefaultStepVisible(): boolean {
    return !this.isValidStepVisible;
  }

  private get isValidStepVisible(): boolean {
    return (
      (this.props.zipCode.isZipCodeValid &&
        !this.props.zipCode.alreadyRegistered &&
        !this.props.isAddressLoading &&
        !this.props.isZipCodeLoading &&
        !this.props.isAddressRegisteredLoading &&
        this.state.addressExists) ||
      false
    );
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  private get showInvalid(): boolean {
    if (!this.state.isFormValidated) {
      return false;
    }

    if (this.props.isZipCodeLoading || this.props.isAddressLoading || this.props.isAddressRegisteredLoading) {
      return false;
    }

    if (this.state.zipCode.zipCode === "" || isNaN(this.state.zipCode.houseNumber)) {
      return false;
    }

    if (this.state.addressExists && !this.props.zipCode.alreadyRegistered) {
      return false;
    }
    return true;
  }

  private get showAddressInfoText(): boolean {
    if (this.state.isFormValidated && (this.state.zipCode.zipCode === "" || isNaN(this.state.zipCode.houseNumber))) {
      return true;
    }
    return false;
  }

  private get isInfoTextShow(): boolean {
    return this.props.isCalculatorSubmitted && (!this.props.zipCode.isZipCodeValid || !this.state.addressExists);
  }

  private onZipCodeChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState((prevState) => ({
      isFormValidated: false,
      zipCode: {
        ...prevState.zipCode,
        zipCode: event.target.value.toUpperCase().replace(/\s/g, ""),
      },
    }));
  }

  private onHouseNumberChange(event: ChangeEvent<HTMLInputElement>): void {
    const houseNumber = event.target.value.replace(/\D+/g, "").substring(0, 6);
    this.setState((prevState) => ({
      isFormValidated: false,
      zipCode: {
        ...prevState.zipCode,
        houseNumber: parseInt(houseNumber),
      },
    }));
  }

  private onHouseNumberAdditionChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState((prevState) => ({
      isFormValidated: false,
      zipCode: {
        ...prevState.zipCode,
        houseNumberAddition: event.target.value,
      },
    }));
  }

  private handleEdit(): void {
    this.props.updateZipCodeValid(false);
    SessionStorageProvider.set(StorageItems.Address, {});
    SessionStorageProvider.set(StorageItems.ZipCodeIsChecked, false);
    SessionStorageProvider.set(StorageItems.AddressExists, false);
    SessionStorageProvider.set(StorageItems.AddressAlreadyRegistered, false);
  }

  private handleZipCodeCheckResult(): void {
    this.props.readDataAddress(
      {
        houseNumber: this.state.zipCode.houseNumber,
        houseNumberAddition: this.state.zipCode.houseNumberAddition,
        zipCode: this.state.zipCode.zipCode,
      },
      (result) => {
        SessionStorageProvider.set(StorageItems.Address, {
          ...result,
          houseNumberAddition: this.state.zipCode.houseNumberAddition,
        });

        // If valid zipCode than keep current session shown
        if (result.zipCode !== null) {
          SessionStorageProvider.set(StorageItems.ZipCodeIsChecked, true);
          SessionStorageProvider.set(StorageItems.AddressExists, true);
          SessionStorageProvider.set(StorageItems.AddressAlreadyRegistered, false);
          this.setState({ addressExists: true });
        } else {
          SessionStorageProvider.set(StorageItems.AddressExists, false);
          this.setState({ addressExists: false });
        }
      }
    );
  }

  private handleCheck(): void {
    const zipCode = document.getElementById("zip-code") as HTMLInputElement;
    const houseNumber = document.getElementById("house-number") as HTMLInputElement;
    const addition = document.getElementById("houseNumberAddition") as HTMLInputElement;

    this.setState({ isFormValidated: true });

    if (zipCode.checkValidity() && houseNumber.checkValidity() && addition.checkValidity()) {
      const dataZipCode = {
        zipCode: zipCode.value,
        houseNumber: parseInt(houseNumber.value),
        houseNumberAddition: addition.value,
      };

      this.props.readZipCodeCheck(dataZipCode, () => {
        ScriptUtil.dataLayerPush({
          active_on_address: this.props.zipCode.isZipCodeValid ? "Yes" : "No",
          currency: "EUR",
          coupon: this.props.discountCode,
          event: DataLayerEvents.Step1CalculatorZipCode,
          step: 1,
          step_name: DataLayerNames.Step1Cleaning,
          value: this.props.costEmployee,
        });
        this.props.readAddressAlreadyRegistered(dataZipCode, (isRegistered: boolean) => {
          if (!isRegistered) {
            this.handleZipCodeCheckResult();
          } else {
            SessionStorageProvider.set(StorageItems.ZipCodeIsChecked, true);
            SessionStorageProvider.set(StorageItems.AddressAlreadyRegistered, true);
          }
        });
      });

      SessionStorageProvider.set(StorageItems.ZipCode, dataZipCode);
    }
  }

  public renderAvailable(): JSX.Element {
    return (
      <div className="card zipcode-checked">
        <div className="card-body">
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <img src={IconCheck} className="icon icon__md me-3" alt="" />
            </div>
            <div>
              <p className="mb-0 zipcode-checked__text">
                <b>{LanguageProvider.t(TranslationMapper.calculator.zipcode.valid.header)}</b>
                <span>
                  {this.props.address?.street} {this.props.address?.houseNumber}
                  {this.props.zipCode.houseNumberAddition}, {this.props.address?.city}
                </span>
              </p>
            </div>
            <div className="d-flex align-items-center ms-auto">
              <Button
                className="btn-link btn-sm text-nowrap"
                label={LanguageProvider.t(TranslationMapper.global.edit)}
                icon={IconEdit}
                onClick={this.handleEdit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderDefault(): JSX.Element {
    const label = this.props.isZipCodeLoading ? false : LanguageProvider.t(TranslationMapper.calculator.zipcode.check);
    return (
      <>
        <div
          className={`row${!this.isDefaultStepVisible ? " d-none" : ""}${this.isFormValidated ? " was-validated" : ""}`}
        >
          <div className="col-12 col-md-4 pe-md-0 mb-3 mb-xxl-0">
            <input
              type="text"
              className={`form-control ${this.showInvalid ? "is-invalid" : ""}`}
              name="zipCode"
              id="zip-code"
              pattern="[0-9]{4}[A-Za-z]{2}"
              maxLength={6}
              placeholder={LanguageProvider.t(TranslationMapper.global.zipcode)}
              value={this.state.zipCode.zipCode}
              required
              onChange={this.onZipCodeChange}
            />
          </div>
          <div className="col-12 col-md-3 pe-md-0 mb-3 mb-xxl-0">
            <input
              type="number"
              className={`form-control ${this.showInvalid ? "is-invalid" : ""}`}
              name="houseNumber"
              id="house-number"
              maxLength={6}
              placeholder={LanguageProvider.t(TranslationMapper.global.house_number)}
              value={this.state.zipCode.houseNumber || ""}
              min={1}
              required
              onChange={this.onHouseNumberChange}
            />
          </div>
          <div className="col-12 col-md-3 pe-md-0 mb-3 mb-md-0">
            <input
              type="text"
              className={"form-control"}
              name="houseNumberAddition"
              id="houseNumberAddition"
              maxLength={5}
              placeholder={LanguageProvider.t(TranslationMapper.global.house_number_addition)}
              value={this.state.zipCode.houseNumberAddition}
              onChange={this.onHouseNumberAdditionChange}
            />
          </div>
          <div className="col-12 col-md-2 mb-0">
            <Button
              className="btn-outline-primary w-100 px-0"
              label={label}
              onClick={(): void => this.handleCheck()}
              isLoading={
                this.props.isZipCodeLoading || this.props.isAddressLoading || this.props.isAddressRegisteredLoading
              }
              disabled={
                this.props.isZipCodeLoading || this.props.isAddressLoading || this.props.isAddressRegisteredLoading
              }
            />
          </div>
          {this.showInvalid && (
            <>
              {this.props.zipCode.alreadyRegistered && (
                <Form.Text muted>
                  {LanguageProvider.t(TranslationMapper.calculator.zipcode.valid.address_already_registered)}
                </Form.Text>
              )}
              {!this.props.zipCode.alreadyRegistered && (
                <Form.Text muted>
                  {LanguageProvider.t(TranslationMapper.calculator.zipcode.valid.invalid_address)}
                </Form.Text>
              )}
            </>
          )}
          {this.showAddressInfoText && (
            <Form.Text muted>{LanguageProvider.t(TranslationMapper.calculator.zipcode.label)}</Form.Text>
          )}
        </div>

        <div className={`invalid-feedback text-md-end mt-2 mt-md-3${this.isInfoTextShow ? " d-block" : ""}`}>
          {LanguageProvider.t(TranslationMapper.calculator.zipcode.warning)}
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <h2 id="zipcode-header">{LanguageProvider.t(TranslationMapper.calculator.zipcode.header)}</h2>
        {this.renderDefault()}
        {this.isValidStepVisible && this.renderAvailable()}
      </>
    );
  }
}

const mapStateToProps = (state: RootState): IZipCodeStateProps => ({
  address: state.registrationState.address,
  costEmployee: state.registrationState.calculation.costEmployee,
  discountCode: state.registrationState.calculator.calculation.discountCode,
  isAddressLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.AddressChecking),
  isZipCodeLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.ZipCodeChecking),
  isAddressRegisteredLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.AddressRegisteredChecking),
  zipCode: state.registrationState.zipCode,
});

const mapDispatchToProps: IZipCodeDispatchProps = {
  readDataAddress: getReadAddress,
  readZipCodeCheck: getReadZipCodeCheck,
  readAddressAlreadyRegistered: getAddressAlreadyRegisteredAsync,
  updateZipCodeValid: setUpdateZipCodeValid,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ZipCode));
